import React, { Component } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Home from "../home/Home";
import Fuel from "../fuel/Fuel";
import MegaBlockNews from "../news/MegaBlockNews";
import { red, amber } from "@material-ui/core/colors";
import { CssBaseline } from "@material-ui/core";
import NoMatch from "./NoMatch";

const theme = createMuiTheme({
  palette: {
    primary: red,
    secondary: amber,
    type: "dark"
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/fuel" component={Fuel} />
            <Route exact path="/megablock" component={MegaBlockNews} />
            <Route component={NoMatch} />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default App;
