import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import { Redirect } from "react-router-dom";

class NoMatch extends Component {
  render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              MBM App - 404 Not Found
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper style={{ padding: 18, margin: 8 }}>
          <Redirect to="/" />
        </Paper>
      </div>
    );
  }
}

export default NoMatch;
