import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import IFuelResponse from "./IFuelResponse";
import FuelItem from "./FuelItem";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";

interface IState {
  fuelprices: IFuelResponse;
  isLoading: Boolean;
}

class Fuel extends React.Component<RouteComponentProps<any>, IState> {
  componentWillMount() {
    const isLoading = true;
    this.setState({ isLoading });
  }

  componentDidMount() {
    axios
      .get("https://newsrain-petrol-diesel-prices-india-v1.p.rapidapi.com/capitals", {
        headers: {
          "X-Mashape-Key": `${process.env.REACT_APP_API_KEY_FUEL}`
        }
      })
      .then(res => {
        const fuelprices = res.data;
        const isLoading = false;
        this.setState({ fuelprices, isLoading });
        console.log(fuelprices);
      });
  }

  render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }} onClick={() => this.props.history.push("/")}>
              MBM App - Fuel Price
            </Typography>
            <IconButton color="inherit" onClick={() => this.props.history.push("/")}>
              <HomeIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {!this.state.isLoading ? (
          <FuelItem fuelPrices={this.state.fuelprices} />
        ) : (
          <div style={{ textAlign: "center", padding: 32 }}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Fuel);
