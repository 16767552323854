import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import HomeIcon from "@material-ui/icons/Home";
import { withRouter } from "react-router-dom";
import { IconButton, CircularProgress } from "@material-ui/core";
import { Alert } from "./IAlert";

function MegaBlockNews(props: any) {
  const [alert, setAlert] = useState<Alert>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let reqConfig = {
      headers: {
        Authorization: `${process.env.REACT_APP_AUTH_TOKEN}`
      }
    };
    const fetchData = async () => {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_HOST}/megablocknews`, reqConfig)
        .then(result => {
          setAlert(result.data);
        })
        .catch(e => console.log(e))
        .finally(() => setLoading(false));
    };

    fetchData();
  }, [props]);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }} onClick={() => props.history.push("/")}>
            Mega Block News
          </Typography>
          <IconButton color="inherit" onClick={() => props.history.push("/")}>
            <HomeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!isLoading ? (
        alert && alert.title ? (
          <div>
            <Typography variant="h6" style={{ padding: 8, margin: 8 }}>
              {alert.title}
            </Typography>
            <Typography variant="subtitle1" style={{ padding: 8, margin: 8 }}>
              <div dangerouslySetInnerHTML={{ __html: alert.content }} />
            </Typography>
            <Typography variant="body1" style={{ padding: 8, margin: 8 }}>
              <br />
              <b>Details</b>
            </Typography>
            <Typography variant="subtitle2" style={{ padding: 8, margin: 8 }}>
              <div dangerouslySetInnerHTML={{ __html: alert.details }} />
            </Typography>
          </div>
        ) : (
          <Typography variant="h6" style={{ padding: 8, margin: 8 }}>
            No Updates
          </Typography>
        )
      ) : (
        <div style={{ textAlign: "center", padding: 16 }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default withRouter(MegaBlockNews);
