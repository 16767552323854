import React, { useState } from "react";
import { ITimeTable } from "./IStation";
import { Card, CardContent, Typography, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import TrainDetails from "./TrainDetails";
import { pink, blueGrey } from "@material-ui/core/colors";

interface TimetableProps extends RouteComponentProps<any> {
  timetable: ITimeTable | undefined;
  stationId: number;
}

function TimeTable(props: TimetableProps) {
  const [fetchDetails, setFetchDetails] = useState(false);

  return (
    <div>
      {props.timetable ? (
        <div style={{ padding: 8 }}>
          <Card raised={true}>
            <ExpansionPanel style={getPanelStyle(props.timetable)} onChange={onExpansionChange}>
              <ExpansionPanelSummary style={{ padding: 4 }}>
                <CardContent style={{ flex: 1, padding: 16 }}>
                  <Grid container direction="row" justify="space-between" alignItems="baseline">
                    <Typography variant="h6" component="h4">
                      {props.timetable.destStation.indicator} - {props.timetable.destStation.name.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      {props.timetable.time}
                    </Typography>
                  </Grid>
                  <Grid container direction="row" justify="space-between" alignItems="baseline">
                    <Typography>{props.timetable.speed}</Typography>
                    <Typography>{props.timetable.cars} CARS</Typography>
                  </Grid>
                  <Grid container direction="row" justify="space-between" alignItems="baseline">
                    <Typography>{props.timetable.platform ? <>#{props.timetable.platform}</> : null}</Typography>
                    <Typography>{props.timetable.platform ? <>{props.timetable.side}</> : null}</Typography>
                  </Grid>
                  {props.timetable.ladiesSpl || props.timetable.notOnSun ? (
                    <Grid container direction="row" justify="space-between" alignItems="baseline">
                      <Typography color="textSecondary" gutterBottom>
                        {props.timetable.ladiesSpl ? <>Ladies Special</> : null}
                      </Typography>
                      <Typography color="textSecondary" gutterBottom>
                        {props.timetable.notOnSun ? <>Not on Sunday</> : null}
                      </Typography>
                    </Grid>
                  ) : null}
                </CardContent>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: 4 }}>
                {fetchDetails ? <TrainDetails trainId={props.timetable.trainNumber} stationId={props.stationId} /> : <></>}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Card>
        </div>
      ) : null}
    </div>
  );

  function getPanelStyle(timetable: ITimeTable): React.CSSProperties | undefined {
    if (timetable.ladiesSpl) {
      return {
        backgroundColor: pink[900],
        padding: 0
      };
    } else if (timetable.speed === "FAST") {
      return {
        backgroundColor: blueGrey[900],
        padding: 0
      };
    }
    return { padding: 0 };
  }

  function onExpansionChange(event: any, expanded: boolean) {
    if (expanded && !fetchDetails) {
      setFetchDetails(expanded);
    }
  }
}

export default withRouter(TimeTable);
