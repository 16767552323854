import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MegaBlockIcon from "@material-ui/icons/Block";
import GasStationIcon from "@material-ui/icons/LocalGasStation";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  IconButton,
  Badge,
  CircularProgress
} from "@material-ui/core";
import { IStation, ITimeTable } from "./IStation";
import axios from "axios";
import TimeTable from "./Timetable";
import { withRouter } from "react-router-dom";

function Home(props: any) {
  const stations = require("./station.json");

  const [lineId, setLineId] = useState("1");
  const [stationId, setStationId] = useState("1");
  const [direction, setDirection] = useState("DOWN");
  const [isLoading, setIsLoading] = useState(false);
  const [timetables, setTimetables] = useState<ITimeTable[]>();

  useEffect(() => {
    const storedLineId = sessionStorage.getItem("lineId");
    if (storedLineId != null) setLineId(storedLineId);
    const storedStationId = sessionStorage.getItem("stationId");
    if (storedStationId != null) setStationId(storedStationId);
    const storedDirection = sessionStorage.getItem("direction");
    if (storedDirection != null) setDirection(storedDirection);
    const storedTimetables = sessionStorage.getItem("timetables");
    if (storedTimetables != null) setTimetables(JSON.parse(storedTimetables));
  }, []);

  let config = {
    headers: {
      Authorization: `${process.env.REACT_APP_AUTH_TOKEN}`
    }
  };
  const fetchData = async () => {
    setIsLoading(true);
    setTimetables([]);
    axios
      .get(`${process.env.REACT_APP_HOST}/timetable/${lineId}/${direction}/${stationId}`, config)
      .then(result => {
        setTimetables(result.data);
        sessionStorage.setItem("lineId", lineId);
        sessionStorage.setItem("stationId", stationId);
        sessionStorage.setItem("direction", direction);
        sessionStorage.setItem("timetables", JSON.stringify(result.data));
      })
      .catch(e => console.log(e))
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" style={{ flexGrow: 1 }}>
            MBM App
          </Typography>
          <IconButton color="inherit" onClick={onFuelClick}>
            <Badge variant="dot" color="secondary">
              <GasStationIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={onMegaBlockNewsClick}>
            <Badge variant="dot" color="secondary">
              <MegaBlockIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <div style={{ padding: 8, paddingTop: 16 }}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item>
            <FormControl>
              <InputLabel shrink>Line</InputLabel>
              <Select value={lineId} onChange={handleLineChange} autoWidth>
                <MenuItem value={1}>Western</MenuItem>
                <MenuItem value={2}>Central</MenuItem>
                <MenuItem value={3}>Harbour</MenuItem>
                <MenuItem value={4}>Trans-Harbour</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel shrink>Station</InputLabel>
              <Select value={stationId} onChange={handleStationChange} autoWidth>
                {// eslint-disable-next-line
                stations.map(function(item: IStation, key: any) {
                  if (item.lines.includes(lineId)) {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <RadioGroup aria-label="Direction" name="direction" value={direction} onChange={handleDirectionChange}>
              <FormControlLabel value="UP" control={<Radio color="primary" style={{ padding: 4 }} />} label="UP" />
              <FormControlLabel value="DOWN" control={<Radio color="primary" style={{ padding: 4 }} />} label="DOWN" />
            </RadioGroup>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={fetchData}>
              Search
            </Button>
          </Grid>
        </Grid>
      </div>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : null}
      {timetables ? (
        <div style={{ padding: 8, paddingTop: 0 }}>
          {timetables!.map((timetable, i) => (
            <div key={i}>
              <TimeTable timetable={timetable} stationId={+stationId} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );

  function handleStationChange(event: any) {
    setStationId(event.target.value);
  }

  function handleDirectionChange(event: any, value: any) {
    setDirection(value);
  }

  function handleLineChange(event: any) {
    /* eslint-disable */
    if (event.target.value == "1") {
      setStationId("1");
    } else if (event.target.value == "2") {
      setStationId("29");
    } else if (event.target.value == "3") {
      setStationId("29");
    } else if (event.target.value == "4") {
      setStationId("46");
    }
    /* eslint-enable */
    setLineId(event.target.value);
  }

  function onMegaBlockNewsClick() {
    props.history.push("megablock");
  }
  function onFuelClick() {
    props.history.push("fuel");
  }
}

export default withRouter(Home);
