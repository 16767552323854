import React, { useState, useEffect } from "react";
import { Table, TableBody, TableRow, TableCell, TableHead, CircularProgress } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios from "axios";
import { ITimeTable } from "./IStation";
import { grey } from "@material-ui/core/colors";

interface TrainDetailsProps extends RouteComponentProps<any> {
  trainId: number;
  stationId: number;
}

function TrainDetails(props: TrainDetailsProps) {
  const [timetables, setTimetables] = useState<ITimeTable[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let reqConfig = {
      headers: {
        Authorization: `${process.env.REACT_APP_AUTH_TOKEN}`
      }
    };

    const fetchData = async () => {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_HOST}/timetable/${props.trainId}`, reqConfig)
        .then(result => {
          setTimetables(result.data);
        })
        .catch(e => console.log(e))
        .finally(() => setLoading(false));
    };

    fetchData();
  }, [props]);

  return (
    <div style={{ flex: 1 }}>
      {!isLoading ? (
        <Table style={{ padding: 0 }}>
          <TableHead>
            <TableRow>
              <TableCell>STATION</TableCell>
              <TableCell style={{ padding: 4, margin: 0 }} align="center">
                TIME
              </TableCell>
              <TableCell style={{ padding: 4, margin: 0 }} align="center">
                PF|S
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timetables!.map((timetable, id) => (
              <TableRow key={id} style={props.stationId === timetable.station.id ? { backgroundColor: grey[900] } : undefined}>
                <TableCell>{timetable.station.name}</TableCell>
                <TableCell style={{ padding: 4, margin: 0 }} align="center">
                  {timetable.time}
                </TableCell>
                <TableCell style={{ padding: 4, margin: 0 }} align="center">
                  {timetable.platform === "" ? null : <> {timetable.platform} | </>}
                  {timetable.side.charAt(0)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div style={{ textAlign: "center", paddingBottom: 16 }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default withRouter(TrainDetails);
