import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IFuelResponse from "./IFuelResponse";

interface FuelProps {
  fuelPrices: IFuelResponse;
}

class FuelItem extends React.Component<FuelProps, []> {
  render() {
    return (
      <div>
        {this.props.fuelPrices.cities.map((city, i) => (
          <div key={i}>
            {city.city === "Mumbai" ? (
              <div style={{ padding: 8 }}>
                <Card className="Card" raised={true}>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      {city.date}
                    </Typography>
                    <Typography variant="h6" component="h2">
                      {city.city}
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      Petrol &#x20B9;{city.petrol}
                    </Typography>
                    <Typography color="textSecondary">
                      was &#x20B9;{city.petrol_yesterday} yesterday ({this.getPriceDiff(city.petrol, city.petrol_yesterday) > 0 ? "+" : null}
                      {this.getPriceDiff(city.petrol, city.petrol_yesterday).toFixed(2)})
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                      Diesel &#x20B9;{city.diesel}
                    </Typography>
                    <Typography color="textSecondary">
                      was &#x20B9;{city.diesel_yesterday} yesterday ({this.getPriceDiff(city.diesel, city.diesel_yesterday) > 0 ? "+" : null}
                      {this.getPriceDiff(city.diesel, city.diesel_yesterday).toFixed(2)})
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  }

  private getPriceDiff = (rate1: string, rate2: string) => {
    return parseFloat(rate1) - parseFloat(rate2);
  };
}

export default FuelItem;
